// Migrated
<template lang="pug">
.d-flex.flex-wrap.gap-3
  .d-flex.flex-column.justify-content-end.text-center(v-for="{value, label, info} in durations" :key="value")
    .duration-info(v-if="info") {{ info }}
    button.btn.d-flex.align-items-center.justify-content-center.duration-btn(
      :id="'duration-' + (value || 'flex') + '-button'",
      :class="[ selectedDuration === value ? 'bg-vivid-turquoise hover-bg-light-turquoise' : 'bg-light-turquoise unchecked-btn' ]",
      type="button"
      @click="handleDuration(value)"
    )
      fa.mr-2(
        v-if="selectedDuration === value"
        :icon="icons.faCheck"
      )
      span.text-nowrap {{ label }}
</template>

<script>
import { faCheck } from '@fortawesome/pro-regular-svg-icons'

export default defineNuxtComponent({
  name: 'DurationSelectButtons',
  props: {
    selectedDuration: {
      type: [Number, Object],
      default: null,
    },
  },

  emits: ['select'],

  data () {
    return {
      icons: {
        faCheck,
      },

      durations: [
        {
          value: 7,
          label: this.$t('charter.duration.week', 1),
        },
        {
          value: 14,
          label: this.$t('charter.duration.week', 2),
        },
        {
          value: 21,
          label: this.$t('charter.duration.week', 3),
        },
        {
          value: 28,
          label: this.$t('charter.duration.week', 4),
        },
        {
          value: null,
          label: this.$t('charterSearchFlexibleDates'),
          info: this.$t('charterSearchSelectFlightHomeInfo'),
        },
      ],
    }
  },

  methods: {
    handleDuration (days) {
      this.$emit('select', days)
    },
  },
})
</script>

<style scoped lang="scss">
.duration-info {
  font-size: .7rem;
  color: $darkest-gray;
}
.duration-btn {
  border-radius: 20px;
  min-width: 127px;
}
.unchecked-btn {
  background-color: $lightest-gray;
  &:hover {
    background-color: $light-gray;
  }
}
</style>
